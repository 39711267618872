<template>
    <div class="animated fadeIn">
        <b-card class="piplines-list">
            <b-card-header class="card-header-main">
                <b-row class="piplines-list-header">
                    <b-col> Permissions for project "{{ activeProject.name }}" </b-col>
                    <b-col md="2">
                        <project-router-link
                            to="/iam/permission/create"
                            v-bind:class="{
                                'pointer-disabled': isSandbox,
                            }"
                        >
                            <b-button variant="primary" class="text-nowrap" :disabled="isSandbox">
                                <i class="icon-plus mr-1"></i>Add
                            </b-button>
                        </project-router-link>
                    </b-col>
                </b-row>
            </b-card-header>
            <b-card-body>
                <wit-table
                    mode="server"
                    :fields="permissionsTable.columns"
                    :request-function="permissionsRequestFunction"
                    striped
                    paginated
                    hide-per-page-selector
                    :default-per-page="10"
                    ref="permissionsTable"
                >
                    <template v-slot:cell(actions)="{item}">
                        <div class="button-row">
                            <EditAction
                                v-if="item.role !== 'Owner'"
                                :editResource="openUpdateAccessModal"
                                :resource="item"
                            />
                            <RemoveAction
                                v-if="item.role !== 'Owner'"
                                :removeResource="showRemoveAccessModal"
                                :resource="item"
                            />
                        </div>
                    </template>
                </wit-table>
            </b-card-body>
        </b-card>

        <b-card class="piplines-list" v-if="showInvitationTable" v-bind:class="{hidden: invitationTableHidden}">
            <b-card-header class="card-header-main">
                <b-row class="piplines-list-header">
                    <b-col> Invitations for project "{{ activeProject.name }}" </b-col>
                </b-row>
            </b-card-header>
            <b-card-body>
                <wit-table
                    mode="server"
                    :fields="invitationTable.columns"
                    :request-function="invitationsRequestFunction"
                    striped
                    paginated
                    hide-per-page-selector
                    :default-per-page="10"
                    ref="invitationTable"
                >
                    <template v-slot:cell(expiresAt)="{item}">{{ parseLocalDate(item.expiresAt) }}</template>
                    <template v-slot:cell(actions)="{item}">
                        <div class="button-row">
                            <EditAction
                                v-if="projectOwner"
                                :editResource="openUpdateInvitationModal"
                                :resource="item"
                            />
                            <RemoveAction
                                v-if="projectOwner"
                                :removeResource="showRemoveInvitationModal"
                                :resource="item"
                            />
                        </div>
                    </template>
                </wit-table>
            </b-card-body>
        </b-card>

        <WitModal
            v-model="showUpdateRecordModal"
            :title="`Update ${modalMode} for user ${updateRow.member}`"
            variant="primary"
            size="md"
        >
            <form>
                <b-row>
                    <b-col>
                        <wit-select
                            :options="['Viewer', 'Editor', 'Admin']"
                            v-model="updateRow.role"
                            placeholder="Select role"
                        ></wit-select>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-button
                            @click="updateRecord"
                            type="submit"
                            size="sm"
                            variant="primary"
                            id="update-access-invite-btn"
                        >
                            <i class="fa fa-dot-circle-o"></i> Update
                        </b-button>
                    </b-col>
                </b-row>
            </form>
        </WitModal>

        <RemoveRecordModal
            v-model="showRemoveModal"
            :removeElement="removeRecord"
            :recordId="removeRecordId"
            @close="removeRecordId = null"
        />

        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import EventBus from '../../../eventBus'

import Loading from '@/components/loading.vue'
import Feedback from '@/components/Feedback.vue'
import WitModal from '@/components/Modals/WitModal.vue'
import WitTable from '@/components/WitTable.vue'
import RemoveRecordModal from '@/components/Modals/RemoveRecordModal.vue'

import EditAction from '@/components/Actions/EditAction.vue'
import RemoveAction from '@/components/Actions/RemoveAction.vue'

export default {
    data() {
        return {
            showUpdateRecordModal: false,
            showRemoveModal: false,
            removeRecordId: null,
            accessError: {
                state: true,
                message: '',
            },
            updateRow: {
                id: null,
                role: null,
                member: null,
            },
            permissionsTable: {
                apiUrl: `${process.env.VUE_APP_NODE_API_HOST}/permissions`,
                columns: ['member', 'role', 'actions'],
            },
            invitationTable: {
                apiUrl: `${process.env.VUE_APP_NODE_API_HOST}/invitations`,
                columns: ['email', 'role', 'expiresAt', 'actions'],
            },
            showInvitationTable: true,
            invitationTableHidden: true,
            modalMode: 'access',
        }
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
        }),
        projectOwner() {
            return this.$store.state.project.active.role === 'Owner'
        },
        isSandbox() {
            return this.activeProject.type === 'SANDBOX'
        },
    },
    components: {
        Loading,
        Feedback,
        RemoveRecordModal,
        EditAction,
        RemoveAction,
        WitModal,
        WitTable,
    },
    methods: {
        async permissionsRequestFunction(params) {
            const response = await this.axios
                .get(this.permissionsTable.apiUrl, {
                    params: {
                        ...params,
                        projectId: this.$store.state.project.active.id,
                    },
                })
                .catch(exception => console.error(exception))

            return {
                data: response.data.data,
                count: response.data.metadata.count,
            }
        },
        async invitationsRequestFunction(params) {
            const response = await this.axios
                .get(this.invitationTable.apiUrl, {
                    params: {
                        ...params,
                        projectId: this.$store.state.project.active.id,
                    },
                })
                .catch(exception => console.error(exception))

            if (response.data.metadata.count === 0) {
                this.showInvitationTable = false
            } else {
                this.invitationTableHidden = false
            }

            return {
                data: response.data.data.map(el => ({...el, email: el.emailInvited})),
                count: response.data.metadata.count,
            }
        },
        removeRecord() {
            if (this.modalMode === 'access') {
                this.removeAccess()
            } else {
                this.removeInvitation()
            }
        },
        removeAccess() {
            const data = {projectId: this.$store.state.project.active.id}
            this.$store.commit('loading/PROCESSING', `Removing access...`)
            this.axios
                .delete(`${process.env.VUE_APP_NODE_API_HOST}/permissions/${this.removeRecordId}`, {data})
                .then(response => {
                    this.showRemoveModal = false
                    this.$store.commit('loading/PROCESSED')
                    this.$refs['permissionsTable'].fetchData()
                })
                .catch(exception => {
                    this.$store.commit('loading/PROCESSED')
                    this.accessError.state = false
                    this.accessError.message = exception.response.data.additionalInfo
                })
        },
        removeInvitation() {
            this.$store.commit('loading/PROCESSING', `Removing the invitation...`)
            this.axios
                .delete(`${process.env.VUE_APP_NODE_API_HOST}/invitations/${this.removeRecordId}`)
                .then(response => {
                    this.showRemoveModal = false
                    this.$store.commit('loading/PROCESSED')
                    this.$refs['invitationTable'].fetchData()
                })
                .catch(exception => {
                    this.$store.commit('loading/PROCESSED')
                    this.accessError.state = false
                    this.accessError.message = exception.response.data.additionalInfo
                })
        },
        showRemoveAccessModal(access) {
            this.removeRecordId = access.id
            this.modalMode = 'access'
            this.showRemoveModal = true
        },
        showRemoveInvitationModal(invitation) {
            this.removeRecordId = invitation.id
            this.modalMode = 'invitation'
            this.showRemoveModal = true
        },
        openUpdateAccessModal(access) {
            this.updateRow.id = access.id
            this.updateRow.role = access.role
            this.updateRow.member = access.member
            this.modalMode = 'access'
            this.showUpdateRecordModal = true
        },
        openUpdateInvitationModal(invitation) {
            this.updateRow.id = invitation.id
            this.updateRow.role = invitation.role
            this.updateRow.member = invitation.emailInvited
            this.modalMode = 'invitation'
            this.showUpdateRecordModal = true
        },
        updateRecord() {
            if (this.modalMode === 'access') {
                this.updateAccess()
            } else {
                this.updateInvitation()
            }
        },
        updateAccess() {
            const data = {role: this.updateRow.role}
            this.$store.commit('loading/PROCESSING', `Updating access...`)
            this.axios
                .post(`${process.env.VUE_APP_NODE_API_HOST}/permissions/${this.updateRow.id}`, data)
                .then(response => {
                    this.$store.commit('loading/PROCESSED')
                    this.$refs['permissionsTable'].fetchData()
                    this.showUpdateRecordModal = false
                })
                .catch(exception => {
                    console.error(exception)
                    this.$store.commit('loading/PROCESSED')
                    this.accessError.state = false
                    this.accessError.message = exception.response.data.additionalInfo
                    this.showUpdateRecordModal = false
                })
        },
        updateInvitation() {
            const data = {role: this.updateRow.role}
            this.$store.commit('loading/PROCESSING', `Updating access...`)
            this.axios
                .put(`${process.env.VUE_APP_NODE_API_HOST}/invitations/${this.updateRow.id}`, data)
                .then(response => {
                    this.$store.commit('loading/PROCESSED')
                    this.$refs['invitationTable'].fetchData()
                    this.showUpdateRecordModal = false
                })
                .catch(exception => {
                    this.$store.commit('loading/PROCESSED')
                    this.accessError.state = false
                    this.accessError.message = exception.response.data.additionalInfo
                    this.showUpdateRecordModal = false
                })
        },
        parseLocalDate(_date) {
            const date = new Date(_date)
            return `${date.toLocaleDateString('pl-PL')} ${date.toLocaleTimeString('pl-PL')}`
        },
    },
}
</script>

<style scoped>
#update-access-invite-btn {
    margin-top: 0.5rem;
}
</style>
